'use client';

import { useEffect } from 'react';

import { ArrowLeftCircleIcon } from '@heroicons/react/16/solid';
import { HomeIcon } from '@heroicons/react/24/solid';
import Image from 'next/image';
import Link from 'next/link';

import isdLogoWide from '@public/logo-wide.png';

import { useAuthContext } from '@/lib/context/AuthContext';

import { logAnalyticsEvent } from '@/lib/firebase/analytics';

import { reactAppUrl } from '@/lib/env';

export default function Header() {
  const { userDoc, isImpersonating, isAuthenticated } = useAuthContext();

  useEffect(() => {
    if (isAuthenticated) {
      logAnalyticsEvent('web_openDispatchScheduler');
    }
  }, [isAuthenticated]);

  const onReturnToISD = () => {
    window.location.href = reactAppUrl;
  };

  return (
    <nav className="app-header navbar max-h-[40px] min-h-[2rem] bg-primary px-2 text-base-100 md:px-12">
      <div className="navbar-start">
        <Link href={'/'} className="relative my-auto font-bold">
          <Image src={isdLogoWide} height={25} alt={'logo'} />
          {!!userDoc && isImpersonating && (
            <div className="absolute right-0 top-[21px] text-[0.5rem]">{`Impersonating ${userDoc.get('nickname')}`}</div>
          )}
        </Link>
      </div>

      <div className="navbar-end">
        <div
          className="btn flex h-auto min-h-0 items-center rounded-full bg-base-100 px-2 py-0.5 text-sm font-bold text-primary"
          onClick={onReturnToISD}
        >
          <ArrowLeftCircleIcon className="size-4 md:mr-2 md:size-6" />
          <div className="flex items-center gap-1 text-xs md:hidden">
            {'Return to '}
            <HomeIcon className="size-4" />
          </div>
          <div className="hidden items-center gap-1 md:flex">
            {'Return to Dashboard '}
            <HomeIcon className="size-4" />
          </div>
        </div>
      </div>
    </nav>
  );
}
